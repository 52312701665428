@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&display=swap');

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    line-height: 1.5;
}
  
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
}

h1, h2, h3, .logo h1 {
    font-family: 'Montserrat', sans-serif;
}

/* Global responsive typography */
:root {
    --header-height: 60px;
    --footer-height: 60px;
    --container-padding: 20px;
}

@media (max-width: 992px) {
    :root {
        --header-height: 56px;
        --container-padding: 15px;
    }

    body {
        font-size: 15px;
    }
}

@media (max-width: 768px) {
    :root {
        --header-height: 52px;
        --footer-height: 52px;
    }

    body {
        font-size: 14px;
        -webkit-tap-highlight-color: transparent;
    }

    /* Prevent iOS zoom on input focus */
    input, select, textarea {
        font-size: 16px !important;
    }
}

@media (max-width: 480px) {
    :root {
        --container-padding: 12px;
    }

    body {
        font-size: 13px;
    }
}
