.contact-page {
    padding: 40px 0;
    background-color: #f8f9fa;
}

.contact-content {
    max-width: 800px;
    margin: 0 auto;
}

.contact-page h1 {
    color: #2c3e50;
    font-size: 2.5rem;
    margin-bottom: 30px;
    text-align: center;
}

.contact-content section {
    margin-bottom: 30px;
    background: #ffffff;
    padding: 25px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.contact-methods {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 30px;
}

.contact-method {
    background: #ffffff;
    padding: 25px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.contact-method h2 {
    color: #2c3e50;
    font-size: 1.4rem;
    margin-bottom: 15px;
}

.contact-method p {
    color: #444;
    line-height: 1.6;
}

@media (max-width: 768px) {
    .contact-page h1 {
        font-size: 2rem;
    }
    
    .contact-content section {
        margin: 0 15px 30px;
        padding: 20px;
    }
    
    .contact-method {
        margin: 0 15px;
    }
}