.faq-page {
    padding: 40px 0;
    background-color: #f8f9fa;
}

.faq-content {
    max-width: 800px;
    margin: 0 auto;
}

.faq-page h1 {
    color: #2c3e50;
    font-size: 2.5rem;
    margin-bottom: 30px;
    text-align: center;
}

.faq-item {
    margin-bottom: 30px;
    background: #ffffff;
    padding: 25px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.faq-item h2 {
    color: #2c3e50;
    font-size: 1.4rem;
    margin-bottom: 15px;
}

.faq-item p {
    color: #444;
    line-height: 1.6;
    margin-bottom: 15px;
}

.faq-item ul {
    list-style-type: disc;
    margin: 0 0 15px 20px;
}

.faq-item li {
    color: #444;
    line-height: 1.6;
    margin-bottom: 8px;
}

@media (max-width: 768px) {
    .faq-page h1 {
        font-size: 2rem;
    }
    
    .faq-item {
        margin: 0 15px 30px;
        padding: 20px;
    }
}