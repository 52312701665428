.tyre-search-form {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 8px;
    margin-top: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .tyre-search-form button {
    margin-top: 10px;
    width: 100%;
  }
  
  .form-control:focus {
    border-color: #007bff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

@media (max-width: 768px) {
    .tyre-search-form {
        padding: 15px;
        margin: 15px;
    }

    .form-group {
        margin-bottom: 12px;
    }

    .form-control {
        height: 44px;  /* Larger touch target */
        font-size: 16px;  /* Prevent iOS zoom on focus */
    }

    .tyre-search-form button {
        height: 44px;
        font-size: 16px;
        margin-top: 15px;
    }

    .form-label {
        font-size: 0.9rem;
        margin-bottom: 5px;
    }
}

@media (max-width: 480px) {
    .tyre-search-form {
        padding: 12px;
        margin: 10px;
    }

    .form-group {
        margin-bottom: 10px;
    }

    .form-control {
        padding: 8px 12px;
    }
}