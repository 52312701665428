.comparison-sidebar {
  position: fixed;
  top: var(--header-height);
  right: 0;
  width: 320px;
  height: calc(100vh - var(--header-height));
  background: #f8f9fa;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  outline: none;
}

.comparison-sidebar:focus-within {
  outline: 2px solid #3498db;
  outline-offset: -2px;
}

.comparison-sidebar.expanded {
  transform: translateX(0);
}

.comparison-sidebar.collapsed {
  transform: translateX(calc(100% - 30px));
}

.sidebar-toggle {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 80px;
  background: linear-gradient(to right, #455a70, #50677f);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 6px 0 0 6px;
  font-weight: bold;
  box-shadow: -2px 2px 6px rgba(0, 0, 0, 0.15);
  font-size: 16px;
  border: none;
  min-width: 44px;
}

.sidebar-toggle:focus {
  outline: 2px solid #3498db;
  outline-offset: 2px;
}

.sidebar-content {
  background: white;
  height: 100%;
  padding: 20px;
  padding-left: 40px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

#comparison-title {
  margin: 0;
  color: #2c3e50;
  font-size: 18px;
  font-weight: 600;
}

.starred-tyres-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 12px;
  height: calc(100% - 50px);
  justify-content: flex-start;
}

.starred-tyre-card {
  background: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  padding: 16px;
  position: relative;
  transition: all 0.2s ease;
}

.starred-tyre-card:focus-within {
  outline: 2px solid #3498db;
  outline-offset: 2px;
}

.starred-tyre-card:hover {
  border-color: #dee2e6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.tyre-thumbnail {
  width: 60px;
  height: 60px;
  object-fit: contain;
  background-color: #f9f9f9;
  border-radius: 4px;
}

.starred-tyre-info {
  width: 100%;
  padding-right: 25px;
}

.starred-tyre-info h4 {
  margin: 0 0 5px 0;
  font-size: 14px;
  color: #333;
  margin-bottom: 8px;
}

.remove-button {
  position: absolute;
  top: 8px;
  right: 8px;
  background: none;
  border: none;
  color: #999;
  cursor: pointer;
  padding: 8px;
  font-size: 16px;
  min-width: 44px;
  min-height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.remove-button:focus {
  outline: 2px solid #3498db;
  outline-offset: 2px;
  color: #e74c3c;
}

.remove-button:hover {
  color: #e74c3c;
}

.tyre-ratings {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  font-size: 12px;
  margin: 8px 0;
}

/* Compact ratings styling */
.tyre-ratings-compact {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin: 12px 0;
}

.rating-item-compact {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.rating-grade-compact {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
  color: white;
  margin-bottom: 4px;
}

.rating-label-compact {
  font-size: 12px;
  color: #666;
  text-align: center;
}

.rating {
  flex: 1;
  text-align: center;
  padding: 4px 6px;
  border-radius: 4px;
  font-weight: bold;
  min-width: 70px;
}

.rating.fuel {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.rating.grip {
  background-color: #e3f2fd;
  color: #1565c0;
}

.rating.noise {
  background-color: #fff3e0;
  color: #e65100;
}

/* Grade-specific colors matching our existing scheme */
.rating[data-grade="A"] {
  background-color: #28a745;
  color: white;
}

.rating[data-grade="B"] {
  background-color: #5cb85c;
  color: white;
}

.rating[data-grade="C"] {
  background-color: #f0ad4e;
  color: white;
}

.rating[data-grade="D"] {
  background-color: #ff9800;
  color: white;
}

.rating[data-grade="E"] {
  background-color: #f44336;
  color: white;
}

.rating[data-grade="F"] {
  background-color: #d32f2f;
  color: white;
}

.rating[data-grade="G"] {
  background-color: #b71c1c;
  color: white;
}

.best-price {
  font-weight: bold;
  color: #16a085;
  margin-top: 10px;
  font-size: 15px;
}

/* Screen reader only class */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

@media (max-width: 992px) {
  .comparison-sidebar {
    width: 280px;
    top: 80px;
    height: calc(100vh - 160px);
  }

  .starred-tyre-card {
    padding: 8px;
    gap: 8px;
  }

  .tyre-thumbnail {
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 768px) {
  .comparison-sidebar {
    position: fixed;
    top: var(--header-height);
    right: -100%;
    width: 85%;
    height: calc(100vh - var(--header-height));
    background: #fff;
    z-index: 1000;
    transition: right 0.3s ease-in-out;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
  }

  .comparison-sidebar.expanded {
    right: 0;
  }

  .comparison-sidebar.mobile.expanded {
    right: 0;
  }

  .sidebar-toggle {
    display: none;
  }

  .close-comparison {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: #666;
    cursor: pointer;
    padding: 10px;
    min-width: 44px;
    min-height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .close-comparison:focus {
    outline: 2px solid #3498db;
    outline-offset: 2px;
  }

  .sidebar-content {
    padding: 15px;
    padding-top: 50px;
    height: calc(100% - 50px);
    overflow-y: auto;
  }

  .starred-tyres-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .starred-tyre-card {
    background: #f8f9fa;
    border-radius: 8px;
    padding: 15px;
    position: relative;
  }

  .remove-button {
    min-width: 44px;
    min-height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tyre-ratings-compact {
    display: flex;
    gap: 15px;
    margin: 10px 0;
  }
}

@media (max-width: 480px) {
  .comparison-sidebar {
    height: 70vh;
  }

  .starred-tyre-info h4 {
    font-size: 13px;
  }

  .rating {
    padding: 3px 6px;
    font-size: 11px;
  }

  .best-price {
    font-size: 14px;
  }
}

/* Scope ComparisonSidebar rating styles */
.comparison-sidebar .tyre-ratings-compact {
  display: flex;
  justify-content: space-between;
  gap: 4px;
  margin: 8px 0;
}

.comparison-sidebar .rating-item-compact {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.comparison-sidebar .rating-grade-compact {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 12px;
  color: white;
  margin-bottom: 2px;
}

.comparison-sidebar .rating-label-compact {
  font-size: 10px;
  color: #666;
  text-align: center;
}

/* High contrast mode support */
@media (forced-colors: active) {
  .comparison-sidebar {
    border: 1px solid CanvasText;
  }

  .sidebar-toggle,
  .close-comparison {
    border: 1px solid ButtonText;
  }

  .starred-tyre-card {
    border: 1px solid CanvasText;
  }

  .rating-grade-compact {
    forced-color-adjust: none;
    border: 1px solid ButtonText;
  }

  .remove-button:hover,
  .remove-button:focus {
    forced-color-adjust: none;
    background: ButtonText;
    color: Canvas;
  }
}