.cookie-consent {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #50677f;
    color: #ffffff;
    padding: 1rem 0;
    z-index: 1000;
    box-shadow: 0 -2px 10px rgba(0,0,0,0.1);
}

.cookie-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
}

.cookie-content p {
    margin: 0;
    color: #ffffff;
}

.cookie-content a {
    color: #5dade2;  /* Lighter blue for better contrast */
    text-decoration: none;
}

.cookie-content a:hover {
    text-decoration: underline;
}

.cookie-buttons {
    display: flex;
    gap: 1rem;
}

@media (max-width: 768px) {
    .cookie-content {
        flex-direction: column;
        text-align: center;
    }
}

.cookie-options {
    margin: 1rem 0;
    padding: 1rem;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 4px;
}

.cookie-option {
    margin-bottom: 1rem;
}

.cookie-option label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 500;
    color: #ffffff;
}

.cookie-option p {
    margin: 0.25rem 0 0 1.5rem;
    font-size: 0.9rem;
    color: #e6e6e6;  /* Lighter gray, better contrast */
}

.cookie-actions {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
}

.cookie-footer {
    margin-top: 1rem;
    font-size: 0.9rem;
    color: #e6e6e6;
}