.tyre-card {
  display: flex;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  position: relative;

  /* Accessibility improvements */
  outline: none;
}

.tyre-card:focus-within {
  outline: 2px solid #3498db;
  outline-offset: 2px;
}

.tyre-thumbnail {
  margin-right: 30px;
  flex-shrink: 0;
  width: 120px;
}

.tyre-thumbnail img {
  width: 100px;
  height: 100px;
  object-fit: contain;
  border: 1px solid #e9ecef;
  border-radius: 4px;
  background-color: #f8f9fa;
}

.tyre-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0; /* Prevent content overflow */
  gap: 10px;
}

.tyre-title {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.tyre-header h3 {
  margin: 0;
  font-size: 1.2rem;
  padding-right: 20px; 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  color: #333; /* Changed from link color to regular text color */
}

.shortlist-btn {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #6c757d;
  transition: color 0.3s;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}

.shortlist-btn.active {
  color: #ffc107;
}

.tyre-specs {
  color: #6c757d;
  margin-top: 0; /* Add this to reduce space */
  margin-bottom: 10px;
}

.tyre-ratings {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 15px;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  padding-bottom: 5px; /* Space for potential scroll */
}

.tyre-ratings::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.tyre-ratings span {
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin: 0; /* Remove margin, using gap instead */
}

.retailer-deals {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  gap: 10px;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom: 5px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.retailer-deals::-webkit-scrollbar {
  display: none;
}

.retailer-deal {
  flex: 0 0 auto;
  width: 150px; /* Fixed width for consistency */
  padding: 10px;
  border: 1px solid #e9ecef;
  border-radius: 5px;
  text-align: center;
  position: relative;
}

.best-deal {
  border: 2px solid #28a745;
  background-color: #f8fff9;
}

.best-price-badge {
  position: absolute;
  top: -8px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #28a745;
  color: white;
  padding: 2px 8px;
  font-size: 0.7rem;
  border-radius: 10px;
  white-space: nowrap;
}

.retailer {
  margin: 0;
  font-size: 0.9rem;
  color: #6c757d;
}

.price {
  font-size: 1.4rem;
  font-weight: bold;
  margin: 5px 0 10px;
  color: #28a745;
}

.more-deals {
  text-align: center;
  margin-top: 15px;
}

.more-details-btn {
  margin: 10px 0;
  display: inline-block;
  color: #0d6efd;
}

@media (max-width: 992px) {
  .retailer-deals {
    flex-wrap: wrap;
  }

  .retailer-deal {
    flex: 1 1 calc(50% - 10px);
    min-width: 140px;
  }
}

@media (max-width: 768px) {
  .tyre-card {
    display: grid;
    grid-template-columns: 100px 1fr;
    grid-template-rows: auto 1fr;
    grid-gap: 12px;
    padding: 12px;
    margin: 10px;
  }

  .tyre-thumbnail {
    width: 100px;
    height: 100px;
    margin: 0;
    grid-column: 1;
    grid-row: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tyre-thumbnail img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  .tyre-content {
    grid-column: 2;
    grid-row: 1 / span 2;
    padding-left: 8px;
    display: flex;
    flex-direction: column;
    min-width: 0;
  }

  /* Keep existing retailer deals styling */
  .retailer-deals {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 10px;
  }

  .retailer-deal {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto auto;
    gap: 10px;
    align-items: center;
    padding: 8px;
    border: 1px solid #e9ecef;
    border-radius: 4px;
  }

  /* Add best deal styling for mobile */
  .retailer-deal.best-deal {
    border: 2px solid #28a745;
    background-color: #f8fff9;
  }

  .retailer {
    margin: 0;
    text-align: left;
  }

  .price {
    margin: 0;
    white-space: nowrap;
  }

  .tyre-info {
    grid-column: 2;
    padding-left: 8px;
  }

  .tyre-info h3 {
    font-size: 15px;
    line-height: 1.3;
    margin: 0 0 4px 0;
    padding-right: 36px; /* Space for star button */
  }

  .tyre-specs {
    font-size: 14px;
    margin: 4px 0;
    color: #666;
  }

  /* Keep ratings and deals below both image and text */
  .tyre-ratings-compact,
  .retailer-deals {
    grid-column: 1 / -1;
  }

  .mobile-header {
    display: grid;
    grid-template-columns: 100px 1fr;
    gap: 12px;
    margin-bottom: 12px;
  }

  .mobile-basic-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  /* Hide desktop layout elements */
  .tyre-content > .tyre-info > .tyre {
    display: none;
  }

  .tyre-content > .tyre-info > .tyre-specs {
    display: none;
  }

  .tyre-content .tyre-thumbnail {
    display: none;
  }

  .mobile-header {
    display: grid;
    grid-template-columns: 100px 1fr;
    gap: 8px;  /* Reduced from 12px */
    margin-bottom: 8px;  /* Reduced from 12px */
  }
}

@media (max-width: 480px) {
  .tyre-card {
    margin: 10px;
    padding: 12px;
  }

  .tyre-thumbnail img {
    width: 100px;
    height: 100px;
  }

  .tyre-ratings span {
    display: flex; /* Keep horizontal */
    margin: 0;
  }

  .more-details-btn {
    width: 100%;
  }
}

/* Compact Rating Styles */
.tyre-ratings-compact {
  display: flex;
  gap: 15px;
  align-items: center;
  margin: 5px 0;
}

.rating-item-compact {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;
}

.rating-grade-compact {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  font-size: 0.8rem;
  font-weight: bold;
}

.rating-label-compact {
  font-size: 0.8rem;
  color: #6c757d;
  font-weight: bold;
  order: -1; /* Moves label above grade circle */
}

/* Rating Grades - reuse from TyreDetailPage */
.grade-A {
  background-color: #28a745;
  color: white;
}

.grade-B {
  background-color: #5cb85c;
  color: white;
}

.grade-C {
  background-color: #f0ad4e;
  color: white;
}

.grade-D {
  background-color: #ff9800;
  color: white;
}

.grade-E {
  background-color: #f44336;
  color: white;
}

.grade-F {
  background-color: #d32f2f;
  color: white;
}

.grade-G {
  background-color: #b71c1c;
  color: white;
}

.noise-rating-wrapper {
  display: flex;
  align-items: center;
  gap: 4px;
}

.noise-db-value {
  font-size: 11px;
  color: #666;
}

/* Mobile-only styles */
@media (max-width: 768px) {
  .mobile-header {
    display: grid;
    grid-template-columns: 100px 1fr;
    gap: 12px;
    margin-bottom: 12px;
  }

  .mobile-basic-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .tyre-content > .tyre-info > .tyre {
    display: none;
  }

  .tyre-content > .tyre-info > .tyre-specs {
    display: none;
  }

  .mobile-basic-info .tyre {
    position: relative;
    padding-right: 30px; /* Space for star button */
  }

  .mobile-basic-info .shortlist-btn {
    position: absolute;
    right: 0;
    top: 0;
  }
}

/* Fix mobile heading alignment */
.mobile-basic-info h3 {
  text-align: left;
  margin: 0;
}

/* Desktop-only styles */
@media (min-width: 769px) {
  .mobile-header {
    display: none;
  }

  .tyre-content {
    display: grid;
    grid-template-columns: 120px 1fr;
    grid-template-rows: auto auto;
    gap: 15px;
  }

  .tyre-content .tyre-thumbnail {
    width: 120px;
    height: 120px;
    flex-shrink: 0;
    grid-row: 1;
    grid-column: 1;
  }

  .tyre-content .tyre-info {
    grid-row: 1;
    grid-column: 2;
  }

  .tyre-content .retailer-deals {
    grid-row: 2;
    grid-column: 1 / -1;
    width: 100%;
  }

  .tyre-content .tyre-thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .retailer-deals {
    display: flex;
    justify-content: center; /* Change from flex-start to center */
    gap: 20px; /* Consistent spacing between deals */
    flex-wrap: wrap;
  }

  .retailer-deal {
    flex: 0 0 auto;
    width: 150px;
  }
}

/* Screen reader only class */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

/* Improved focus styles for interactive elements */
.shortlist-btn:focus,
.btn:focus,
a:focus {
  outline: 2px solid #3498db;
  outline-offset: 2px;
  box-shadow: none;
}

/* High contrast mode improvements */
@media (forced-colors: active) {
  .tyre-card {
    border: 1px solid CanvasText;
  }
  
  .shortlist-btn.active {
    forced-color-adjust: none;
    color: Highlight;
  }
  
  .best-price-badge {
    forced-color-adjust: none;
    background-color: Highlight;
    color: Canvas;
  }
}