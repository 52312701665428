.results-page {
  padding: 20px 0;
  position: relative;
}

.search-summary {
  margin-bottom: 20px;
  color: #6c757d;
}

/* Layout */
.results-page-container {
  display: flex;
  position: relative;
  gap: 30px;
  max-width: 1400px;  /* Add max-width for larger screens */
  margin: 0 auto;     /* Center the container */
  padding: 0 20px;    /* Add padding for breathing room */
}

/* Add these new styles */
.col-md-3 {
  width: 25%;
  flex: 0 0 25%; /* Don't grow or shrink */
}

.col-md-9 {
  width: 75%;
  flex: 1; /* Allow growing but not shrinking */
}

.main-content {
  flex: 1;
  min-width: 0;      /* Prevent flex item from overflowing */
}

.results-page .main-content {
  flex: 1;
  min-width: 0;      /* Prevent flex item from overflowing */
}

/* Loading and No Results States */
.loading-spinner {
  text-align: center;
  padding: 40px;
  color: #007bff;
}

.no-results {
  text-align: center;
  padding: 40px;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.no-results h3 {
  color: #343a40;
  margin-bottom: 10px;
}

/* Rating filters */
.rating-filters, .noise-filters {
display: flex;
flex-wrap: wrap;
gap: 8px;
margin-top: 8px;
}

.rating-button, .noise-button {
padding: 5px 10px;
border: 1px solid #ddd;
background: white;
border-radius: 4px;
cursor: pointer;
font-size: 13px;
transition: all 0.2s ease;
}

.rating-button.selected, .noise-button.selected {
background: #3498db;
color: white;
border-color: #3498db;
}

/* Clickable tyre titles */
.tyre-title a {
color: inherit;
text-decoration: none;
transition: color 0.2s ease;
}

.tyre-title a:hover {
color: #3498db;
}

/* Adjust layout for sidebar */
.results-page-container {
display: flex;
position: relative;
gap: 30px;
}

/* Add to ResultsPage.css */
.dual-slider {
margin: 20px 0;
}

.dual-slider .rc-slider {
margin: 10px 0;
}

.price-range {
display: flex;
justify-content: space-between;
margin-bottom: 10px;
}

.form-range {
width: 100%;
margin: 8px 0;
-webkit-appearance: none;
appearance: none;
height: 4px;
background: #ddd;
border-radius: 2px;
}

.form-range::-webkit-slider-thumb {
-webkit-appearance: none;
appearance: none;
width: 16px;
height: 16px;
background: #3498db;
border-radius: 50%;
cursor: pointer;
}

.form-range::-moz-range-thumb {
width: 16px;
height: 16px;
background: #3498db;
border-radius: 50%;
cursor: pointer;
border: none;
}

/* Responsive Design */
@media (max-width: 992px) {
.results-page-container {
  flex-direction: column;
  padding: 0 15px;
}

.col-md-3, .col-md-9 {
  width: 100%;
  flex: none;
}

.main-content {
  margin-right: 0;
  padding-right: 0;
}

.rating-filters, .noise-filters {
  justify-content: center;
}
}

@media (max-width: 768px) {
.results-page {
  padding: 15px 0;
}

.search-summary {
  font-size: 0.9rem;
  padding: 0 15px;
}

.no-results {
  margin: 0 15px;
}

.rating-button, .noise-button {
  padding: 4px 8px;
  font-size: 12px;
}

.dual-slider {
  margin: 15px;
}

/* Mobile Filter Panel Container */
.mobile-filter-container {
  position: fixed;
  top: var(--header-height);
  left: -100%;
  width: 85%;
  height: calc(100vh - var(--header-height));
  background: #fff;
  z-index: 1000;
  transition: left 0.3s ease-in-out;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
}

.mobile-filter-container.active {
  left: 0;
}

/* Filter Toggle Button */
.filter-toggle {
  position: fixed;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #50677f;
  color: white;
  padding: 12px;
  border-radius: 0 8px 8px 0;
  z-index: 999;
  border: none;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

/* Overlay */
.filter-overlay {
  position: fixed;
  top: var(--header-height);
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: 999;
}

.filter-overlay.active {
  opacity: 1;
  visibility: visible;
}

/* Compare Toggle Button */
.compare-toggle {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: #50677f;
  color: white;
  padding: 12px;
  border-radius: 8px 0 0 8px;
  z-index: 999;
  border: none;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
}

.compare-count {
  position: absolute;
  top: -8px;
  left: -8px;
  background: #e74c3c;
  color: white;
  border-radius: 50%;
  min-width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
}

/* Panel Overlay */
.panel-overlay {
  position: fixed;
  top: var(--header-height);
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: 998;
}

.panel-overlay.active {
  opacity: 1;
  visibility: visible;
}
}

@media (max-width: 480px) {
.results-page-container {
  gap: 15px;
}

.rating-filters, .noise-filters {
  gap: 5px;
}

.loading-spinner {
  padding: 20px;
}
}

/* Base desktop styles */
.results-page-container {
    display: flex;
    position: relative;
    gap: 30px;
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 20px;
}

.results-page .main-content {
    flex: 1;
    min-width: 0;
    /* Remove these as they're not needed with proper flex layout */
    padding-right: 20px; /* Remove this */
    margin-right: 30px; /* Remove this */
}

@media (max-width: 768px) {
    .results-page-container {
        padding: 0;  /* Remove padding on mobile */
        gap: 15px;
    }

    .results-page .main-content {
        padding-right: 0;
        margin-right: 0;
    }

    .search-summary {
        padding: 0 15px;  /* Move padding to individual elements that need it */
    }
}