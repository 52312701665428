.hero {
  background-color: #f8f9fa;
  padding: 60px 0;
  text-align: center;
}

.hero h1 {
  font-size: 2.5rem;
  margin-bottom: 15px;
}

.hero p {
  font-size: 1.2rem;
  color: #6c757d;
  margin-bottom: 30px;
}

.how-to-section {
  padding: 40px 0; /* Reduced from 60px */
  background-color: #fff;
}

.how-to-section h2 {
  text-align: center;
  margin-bottom: 20px; /* Reduced from 40px */
}

.instruction-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px; /* Added margin bottom */
}

.instruction-cards .card {
  width: 300px;
  text-align: center;
}

.external-tools {
  background-color: #f8f9fa;
  padding: 40px 0; /* Reduced from 60px */
  text-align: center;
  margin-top: -20px; /* Pull section up slightly */
}

.tool-links {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .hero {
    padding: 40px 15px;
  }

  .hero h1 {
    font-size: 2rem;
  }

  .hero p {
    font-size: 1.1rem;
    padding: 0 15px;
  }

  .how-to-section {
    padding: 30px 15px;
  }

  .how-to-section h2 {
    font-size: 1.8rem;
    margin-bottom: 15px;
  }

  .instruction-cards {
    gap: 15px;
    padding: 0 15px;
  }

  .instruction-cards .card {
    width: 100%;
    max-width: 300px;
  }

  .external-tools {
    padding: 30px 15px;
  }

  .tool-links {
    gap: 10px;
    padding: 0 15px;
  }
}

@media (max-width: 480px) {
  .hero h1 {
    font-size: 1.8rem;
  }

  .hero p {
    font-size: 1rem;
  }
}