.tyre-detail-page {
    padding: 30px 0;
  }
  
  .back-link {
    margin-bottom: 20px;
  }
  
  .tyre-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 30px;
  }
  
  .tyre-header h1 {
    margin: 0 0 10px 0;
  }
  
  .tyre-specs {
    color: #6c757d;
    font-size: 1.1rem;
    margin: 0;
  }
  
  .shortlist-btn-large {
    padding: 8px 16px;
    background-color: #f8f9fa;
    border: 1px solid #ced4da;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s;
  }
  
  .shortlist-btn-large.active {
    background-color: #ffc107;
    border-color: #ffc107;
    color: #212529;
  }
  
  .tyre-ratings-detailed {
    display: flex;
    gap: 20px;
    margin-bottom: 40px;
  }
  
  .rating-item {
    flex: 1;
    text-align: center;
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 8px;
  }
  
  .rating-grade {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 50%;
    font-weight: bold;
    margin: 10px 0;
  }
  
  .grade-A {
    background-color: #28a745;
    color: white;
  }
  
  .grade-B {
    background-color: #5cb85c;
    color: white;
  }
  
  .grade-C {
    background-color: #f0ad4e;
    color: white;
  }
  
  .grade-D {
    background-color: #ff9800;
    color: white;
  }
  
  .grade-E {
    background-color: #f44336;
    color: white;
  }
  
  .grade-F {
    background-color: #d32f2f;
    color: white;
  }
  
  .grade-G {
    background-color: #b71c1c;
    color: white;
  }
  
  .noise-level {
    font-size: 1.5rem;
    font-weight: bold;
    display: block;
    margin: 10px 0;
  }
  
  .all-deals {
    margin-top: 20px;
  }
  
  .best-price-row {
    background-color: #f8fff9;
  }
  
  .best-price-label {
    display: inline-block;
    background-color: #28a745;
    color: white;
    font-size: 0.75rem;
    padding: 2px 8px;
    border-radius: 10px;
    margin-left: 10px;
  }
  
  .price-cell {
    font-weight: bold;
    font-size: 1.1rem;
  }

  .tyre-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
  }
  
  .tyre-detail-image {
    max-width: 100%;
    height: auto;
    max-height: 300px;
    object-fit: contain;
  }

  .noise-db {
    display: block;
    margin-top: 8px;
    font-size: 1.1rem;
    color: #495057;
  }

  .noise-db.invisible {
    visibility: hidden;
    height: 1.1rem;  /* Match height of visible noise-db */
    margin-top: 8px;  /* Match margin of visible noise-db */
  }

  .price-disclaimer {
    color: #666;
    font-size: 0.9rem;
    margin-bottom: 1rem;
    font-style: italic;
  }

  @media (max-width: 992px) {
    .tyre-header {
      flex-direction: column;
      gap: 20px;
    }
  
    .tyre-ratings-detailed {
      flex-wrap: wrap;
      gap: 15px;
    }
  
    .rating-item {
      flex: 1 1 calc(50% - 15px);
      min-width: 200px;
    }
  }
  
  @media (max-width: 768px) {
    .tyre-detail-page {
      padding: 20px 15px;
    }
  
    .tyre-header h1 {
      font-size: 1.8rem;
    }
  
    .tyre-specs {
      font-size: 1rem;
    }
  
    .rating-item {
      flex: 1 1 100%;
    }
  
    .tyre-image-container {
      padding: 15px;
    }
  
    .tyre-detail-image {
      max-height: 250px;
    }
  
    .price-cell {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    .tyre-header h1 {
      font-size: 1.5rem;
    }
  
    .shortlist-btn-large {
      width: 100%;
      margin-top: 15px;
    }
  
    .rating-grade {
      width: 35px;
      height: 35px;
      line-height: 35px;
      font-size: 0.9rem;
    }
  
    .noise-level {
      font-size: 1.3rem;
    }
  
    .noise-db {
      font-size: 1rem;
    }
  }