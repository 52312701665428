.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;  /* Changed from 100vw to prevent horizontal overflow */
    overflow-x: hidden;
}
  
.main-content {
    flex: 1;
    background-color: #f5f7fa;
}

/* Global button styles */
.btn {
    transition: all 0.2s ease;
}

/* Container widths */
.container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
}

/* Responsive breakpoints */
@media (max-width: 992px) {
    .container {
        max-width: 100%;
        padding: 0 15px;
    }

    .main-content {
        padding: 15px 0;
    }
}

@media (max-width: 768px) {
    .main-content {
        padding-top: calc(var(--header-height) + 10px);  /* Use CSS variable plus spacing */
        padding-bottom: 10px;
        -webkit-overflow-scrolling: touch;
    }

    .btn {
        padding: 8px 16px;
        font-size: 14px;
        min-height: 44px;  /* Touch-friendly target size */
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    /* Improve form controls on mobile */
    .form-control {
        min-height: 44px;  /* Touch-friendly height */
        margin-bottom: 16px;
    }

    /* Enhance touch feedback */
    .btn:active {
        transform: scale(0.98);
    }
}

/* Prevent pull-to-refresh on mobile */
@media (max-width: 768px) {
    body {
        overscroll-behavior-y: contain;
    }
}