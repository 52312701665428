.tyre-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.tyre-card {
  display: flex;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
}

.tyre-card img {
  width: 120px;
  height: 120px;
  object-fit: contain;
  margin-right: 20px;
}

.tyre-thumbnail {
  flex: 0 0 120px; /* Prevent thumbnail from shrinking */
  margin-right: 20px;
}

.star-icon {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 24px;
  cursor: pointer;
  color: #ddd;
  transition: color 0.2s ease;
}

.star-icon:hover {
  color: #f1c40f;
}

.tyre-title {
  margin: 0 0 0px 0;
  font-size: 1.2rem;
}

.tyre-title a {
  color: inherit;
  text-decoration: none;
  transition: color 0.2s;
}

.tyre-title a:hover {
  color: #3498db;
}

.tyre-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 20px; /* Add space between thumbnail and content */
  min-width: 0; /* Prevent content overflow */
}

.ratings {
  display: flex;
  gap: 15px;
  margin: 10px 0;
}

.rating {
  display: inline-flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.9rem;
}

.price-info {
  margin-top: auto;
}

.price {
  font-size: 1.4rem;
  font-weight: bold;
  color: #2ecc71;
}

.more-deals-button {
  background: #3498db;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 15px;
  transition: background-color 0.2s;
}

.more-deals-button:hover {
  background: #2980b9;
}

@media (max-width: 992px) {
  .tyre-list {
    gap: 12px;
  }

  .tyre-info {
    margin-left: 15px;
  }

  .ratings {
    flex-wrap: wrap;
    gap: 10px;
  }
}

@media (max-width: 768px) {
  .tyre-card {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 15px;
  }

  .tyre-card img {
    margin: 0 0 15px 0;
  }

  .star-icon {
    top: 10px;
    right: 10px;
  }

  .tyre-thumbnail {
    flex: none;
    margin: 0 0 15px 0;
    text-align: center;
  }

  .tyre-info {
    margin-left: 0;
    text-align: center;
  }

  .tyre-title {
    font-size: 1.1rem;
  }

  .ratings {
    justify-content: center;
  }

  .price-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .more-deals-button {
    margin: 10px 0 0 0;
    width: 100%;
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .tyre-card {
    padding: 12px;
  }

  .tyre-card img {
    width: 100px;
    height: 100px;
  }

  .star-icon {
    font-size: 20px;
  }

  .rating {
    font-size: 0.8rem;
    padding: 3px 6px;
  }

  .price {
    font-size: 1.2rem;
  }
}