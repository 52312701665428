.site-header {
    background: linear-gradient(to bottom, #50677f, #455a70);
    padding: 15px 0;
    color: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    position: relative;
}

.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    text-decoration: none;
    color: white;
    display: flex;
    align-items: center;
    gap: 12px;
}

.logo h1 {
    margin: 0;
    font-size: 1.8rem;
    font-weight: 600;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    letter-spacing: -0.02em;
}

.main-nav ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.main-nav a {
    color: white;
    text-decoration: none;
    padding: 10px 15px;
    display: block;
}

.main-nav a:hover {
    text-decoration: underline;
}

.logo-icon {
    height: 48px;
    width: auto;
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
}

/* Mobile menu button */
.menu-button {
    display: none;  /* Hidden by default on desktop */
    background: none;
    border: none;
    color: white;
    padding: 10px;
    cursor: pointer;
    min-width: 44px;
    min-height: 44px;
    align-items: center;
    justify-content: center;
}

.menu-button svg {
    width: 24px;
    height: 24px;
    display: block;  /* Ensure SVG is visible when button is shown */
}

/* Hide secondary nav by default (desktop) */
.nav-list.secondary {
    display: none;
}

@media (max-width: 768px) {
    .site-header {
        padding: 10px 15px;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1000;
    }

    .logo h1 {
        font-size: 1.4rem;
    }

    .logo-icon {
        height: 40px;
    }

    .menu-button {
        display: flex;  /* Show menu button on mobile */
        align-items: center;
        justify-content: center;
        position: relative;
        width: 24px;
        height: 24px;
    }

    .main-nav {
        position: fixed;
        top: 60px;
        right: -100%;  /* Changed from left to right */
        width: 100%;
        height: calc(100vh - 60px);
        background-color: #50677f;
        z-index: 1000;
        transition: all 0.3s ease-in-out;
        padding: 1rem 0;
    }

    .main-nav.active {
        right: 0;  /* Changed from left to right */
        box-shadow: -2px 0 5px rgba(0,0,0,0.2);  /* Adjusted shadow direction */
    }

    .nav-list {
        padding: 0 1.5rem;
    }

    .nav-list.primary,
    .nav-list.secondary {
        margin: 0;
        padding: 0 1.5rem;
        border: none;
    }

    .menu-button svg {
        transition: transform 0.3s ease-in-out;
    }

    .menu-button.active svg {
        transform: rotate(180deg);
    }

    .main-nav ul {
        flex-direction: column;
    }

    .main-nav a {
        opacity: 0;
        transform: translateX(20px);  /* Changed direction */
        transition: all 0.3s ease-in-out;
        padding: 0.75rem 0;
        display: block;
    }

    .main-nav.active a {
        opacity: 1;
        transform: translateX(0);
    }

    .nav-list li:nth-child(1) a { transition-delay: 0.1s; }
    .nav-list li:nth-child(2) a { transition-delay: 0.15s; }
    .nav-list li:nth-child(3) a { transition-delay: 0.2s; }
    .nav-list li:nth-child(4) a { transition-delay: 0.25s; }
    .nav-list li:nth-child(5) a { transition-delay: 0.3s; }
    .nav-list li:nth-child(6) a { transition-delay: 0.35s; }
    .nav-list li:nth-child(7) a { transition-delay: 0.4s; }
    .nav-list li:nth-child(8) a { transition-delay: 0.45s; }

    .nav-list.secondary {
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid rgba(255,255,255,0.2);
        display: block;
    }

    .main-nav ul {
        flex-direction: column;
    }

    .main-nav a {
        padding: 15px 20px;
        border-top: 1px solid rgba(255,255,255,0.1);
        /* Add touch-friendly target size */
        min-height: 44px;
        display: flex;
        align-items: center;
    }

    /* Add subtle touch feedback */
    .main-nav a:active {
        background-color: rgba(255,255,255,0.1);
    }

    /* Add padding to main content to account for fixed header */
    .main-content {
        padding-top: calc(var(--header-height) + 10px);
    }

    .menu-button {
        position: relative;
        width: 24px;
        height: 24px;
    }

    /*
    .menu-icon,
    .menu-icon::before,
    .menu-icon::after {
        position: absolute;
        width: 24px;
        height: 2px;
        background-color: white;
        transition: all 0.3s ease;
    }

    .menu-icon {
        top: 50%;
        transform: translateY(-50%);
    }

    .menu-icon::before {
        content: '';
        top: -8px;
    }

    .menu-icon::after {
        content: '';
        bottom: -8px;
    }

    .menu-button.active .menu-icon {
        background-color: transparent;
    }

    .menu-button.active .menu-icon::before {
        transform: rotate(45deg) translate(5px, 5px);
    }

    .menu-button.active .menu-icon::after {
        transform: rotate(-45deg) translate(5px, -5px);
    }
    */
}

@media (max-width: 480px) {
    .logo h1 {
        font-size: 1.2rem;
    }

    .logo-icon {
        height: 35px;
    }
}