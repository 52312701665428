.filter-panel {
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    min-height: 100vh; /* Ensures consistent height */
    position: sticky;
    top: 20px;

    /* Accessibility improvements */
    outline: none;
}

.filter-panel:focus-within {
    outline: 2px solid #3498db;
    outline-offset: 2px;
}

.filter-panel h3 {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 1.2rem;
}
  
.filter-section {
    margin-bottom: 25px;
}
  
.filter-section h4 {
    font-size: 1rem;
    margin-bottom: 10px;
}
  
.price-range {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 0.9rem;
}
  
.form-range {
    width: 100%;
    margin-bottom: 15px;
}
  
.form-check {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    min-height: 44px;
}

.form-check-input {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

.form-check-input:focus {
    outline: 2px solid #3498db;
    outline-offset: 2px;
    box-shadow: none;
}

/* Add these new styles */
.rating-filters, .noise-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 10px;
}

.rating-button, .noise-button {
    padding: 6px 10px;
    border: 1px solid #ddd;
    background: white;
    border-radius: 4px;
    cursor: pointer;
    font-size: 13px;
    transition: all 0.2s ease;
    min-width: 36px;
    text-align: center;
}

.rating-button:hover, .noise-button:hover {
    border-color: #3498db;
    color: #3498db;
}

.rating-button.selected, .noise-button.selected {
    background: #3498db;
    color: white;
    border-color: #3498db;
}

.rating-button.selected {
  background-color: #28a745;
  color: white;
  border-color: #28a745;
}

.rating-button.better-than-selected {
  background-color: #e9ecef;
  color: #28a745;
  border-color: #28a745;
}

.noise-button {
    font-size: 12px;
    padding: 6px 8px;
}

.noise-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    min-height: 40px; /* Maintains button row height */
}

.max-price-input {
    position: relative;
    margin: 10px 0;
}

.currency-symbol {
    position: absolute;
    left: 10px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    color: #666;
    pointer-events: none;
}

.max-price-input input {
    width: 100%;
    padding-left: 25px;
    height: 38px;
}

.max-price-input input:focus {
    outline: none;
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}

.text-muted {
    display: block;
    text-align: center;
    margin-top: 8px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .rating-button, .noise-button {
        padding: 8px 12px;
        font-size: 14px;
    }
}

.rating-button.selected[value="A"] {
    background-color: #28a745;
    color: white;
    border-color: #28a745;
}

.rating-button.selected[value="B"] {
    background-color: #5cb85c;
    color: white;
    border-color: #5cb85c;
}

.rating-button.selected[value="C"] {
    background-color: #f0ad4e;
    color: white;
    border-color: #f0ad4e;
}

.rating-button.selected[value="D"] {
    background-color: #ff9800;
    color: white;
    border-color: #ff9800;
}

.rating-button.selected[value="E"] {
    background-color: #f44336;
    color: white;
    border-color: #f44336;
}

.rating-button.selected[value="F"] {
    background-color: #d32f2f;
    color: white;
    border-color: #d32f2f;
}

.rating-button.selected[value="G"] {
    background-color: #b71c1c;
    color: white;
    border-color: #b71c1c;
}

.rating-button {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid #dee2e6;
  background: white;
  cursor: pointer;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 44px;
  min-height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid transparent;
  position: relative;
}

.rating-button:focus {
  outline: none;
  border-color: #3498db;
}

.rating-button:focus:not(:focus-visible) {
  border-color: transparent;
}

.rating-button:focus-visible {
  border-color: #3498db;
}

.rating-button.selected[value="A"] { background-color: #28a745; color: white; }
.rating-button.selected[value="B"] { background-color: #5cb85c; color: white; }
.rating-button.selected[value="C"] { background-color: #f0ad4e; color: white; }
.rating-button.selected[value="D"] { background-color: #ff9800; color: white; }
.rating-button.selected[value="E"] { background-color: #f44336; color: white; }
.rating-button.selected[value="F"] { background-color: #d32f2f; color: white; }
.rating-button.selected[value="G"] { background-color: #b71c1c; color: white; }

@media (max-width: 992px) {
    .filter-panel {
        position: static;
        min-height: auto;
        margin-bottom: 20px;
    }

    .rating-filters, .noise-filters {
        justify-content: center;
    }
}

@media (max-width: 768px) {
    .filter-panel {
        padding: 12px;
        margin: 0 15px 15px;
    }

    .filter-section {
        margin-bottom: 20px;
    }

    .rating-button, .noise-button {
        width: 40px;
        height: 40px;
        font-size: 16px;
    }

    .price-range {
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }

    .max-price-input {
        margin: 15px 0;
        position: relative;
    }

    .max-price-input input {
        min-height: 44px;
        text-align: center;
        padding-left: 30px;
    }

    .form-range {
        margin: 20px 0;
    }

    .currency-symbol {
        left: calc(10% + 10px);
        font-size: 16px;
    }
}

@media (max-width: 480px) {
    .filter-section h4 {
        text-align: center;
    }

    .rating-filters, .noise-filters {
        gap: 6px;
    }

    .rating-button, .noise-button {
        width: 35px;
        height: 35px;
        font-size: 14px;
    }

    .form-check {
        padding-left: 0;
        display: flex;
        justify-content: center;
    }
}

@media (max-width: 768px) {
    .filter-panel {
        height: 100%;
        border-radius: 0;
        position: relative;
        top: 0;
        overflow-y: auto;
        padding: 20px;
    }

    .filter-panel h3 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: sticky;
        top: 0;
        background: #fff;
        padding: 10px 0;
        margin: 0 0 15px;
        z-index: 1;
    }

    .rating-filters, .noise-filters {
        justify-content: flex-start;
    }

    .rating-button, .noise-button {
        min-height: 44px;
        min-width: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 768px) {
    .filter-panel {
        display: flex;
        flex-direction: column;
        height: 100%;
        border-radius: 0;
        position: relative;
        top: 0;
    }

    .filter-header {
        padding: 15px;
        background: #fff;
        border-bottom: 1px solid #eee;
        position: sticky;
        top: 0;
        z-index: 2;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .close-filters {
        background: none;
        border: none;
        padding: 8px;
        color: #666;
        cursor: pointer;
        min-width: 44px;
        min-height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 44px;
        min-height: 44px;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .filter-content {
        flex: 1;
        overflow-y: auto;
        padding: 15px;
        -webkit-overflow-scrolling: touch;
    }

    .filter-footer {
        padding: 15px;
        background: #fff;
        border-top: 1px solid #eee;
        position: sticky;
        bottom: 0;
        z-index: 2;
    }

    .apply-filters {
        width: 100%;
        padding: 12px;
        background: #50677f;
        color: white;
        border: none;
        border-radius: 6px;
        font-weight: 600;
        min-height: 44px;
        min-height: 44px;
        font-size: 16px;
        padding: 12px;
    }

    /* Make filter sections more compact */
    .filter-section {
        margin-bottom: 20px;
        padding-bottom: 15px;
        border-bottom: 1px solid #eee;
    }

    .filter-section:last-child {
        border-bottom: none;
    }

    /* Improve rating buttons layout */
    .rating-filters {
        justify-content: flex-start;
        gap: 10px;
    }

    .rating-button {
        flex: 0 0 44px;
        height: 44px;
    }
}

/* High contrast mode support */
@media (forced-colors: active) {
  .filter-panel {
    border: 1px solid CanvasText;
  }

  .rating-button {
    border: 1px solid ButtonText;
  }

  .rating-button.selected {
    forced-color-adjust: none;
    background: Highlight;
    color: Canvas;
  }

  .form-check-input:checked {
    forced-color-adjust: none;
    background: Highlight;
    border-color: Highlight;
  }
}

/* Keyboard focus indicators */
.form-control:focus {
  outline: 2px solid #3498db;
  outline-offset: 2px;
  box-shadow: none;
}