.site-footer {
    background: linear-gradient(to bottom, #455a70, #50677f);
    color: white;
    padding: 40px 0 20px;
    box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.15);
}

.footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
    gap: 30px;
}

.footer-section {
    flex: 1;
    min-width: 200px;
    margin-bottom: 20px;
}

.footer-section h3 {
    margin-top: 0;
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 15px;
    letter-spacing: -0.02em;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.footer-section ul {
    list-style: none;
    padding: 0;
}

.footer-section li {
    margin-bottom: 8px;
}

.footer-section a {
    color: #e0e0e0;
    text-decoration: none;
    transition: all 0.2s ease;
    padding: 4px 0;
    display: inline-block;
}

.footer-section a:hover {
    color: white;
    transform: translateX(2px);
}

.copyright {
    text-align: center;
    padding-top: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.8);
    font-size: 0.9rem;
}

@media (max-width: 992px) {
    .footer-content {
        justify-content: flex-start;
        gap: 40px;
    }

    .footer-section {
        flex: 0 0 calc(50% - 20px);
        margin-bottom: 30px;
    }
}

@media (max-width: 768px) {
    .site-footer {
        padding: 30px 15px 20px;
    }

    .footer-content {
        flex-direction: column;
        gap: 30px;
    }

    .footer-section {
        flex: 1 1 100%;
        text-align: center;
        margin-bottom: 20px;
    }

    .footer-section:last-child {
        margin-bottom: 0;
    }

    .footer-section ul {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .footer-section li {
        margin-bottom: 12px;
        width: 100%;
        max-width: 300px;
    }

    .footer-section a {
        padding: 12px 16px;
        width: 100%;
        text-align: center;
        background-color: rgba(255, 255, 255, 0.05);
        border-radius: 8px;
        transition: all 0.2s ease;
    }

    .footer-section a:hover {
        background-color: rgba(255, 255, 255, 0.1);
        transform: translateY(-1px);
    }

    .footer-section a:active {
        transform: translateY(0);
        background-color: rgba(255, 255, 255, 0.15);
    }

    .copyright {
        margin-top: 20px;
        padding-top: 15px;
    }
}

@media (max-width: 480px) {
    .site-footer {
        padding: 20px 15px 15px;
    }

    .footer-section h3 {
        font-size: 1.1rem;
    }

    .footer-section li {
        font-size: 0.9rem;
    }
}